// !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="ZHQQplCcCPHuUkfsjZsjqTl34vUwdUQu";;analytics.SNIPPET_VERSION="4.15.3";
// analytics.load("ZHQQplCcCPHuUkfsjZsjqTl34vUwdUQu");
// analytics.page();
// }}();
import { store } from '../store';
export default function Segments() {

  const Analytics = window.analytics;
  const SendData = (title, data) => {
    window.analytics.ready(() => {
      window.analytics.track(title, data, {
        integrations: {
          "Google Analytics": {
            clientId: window.analytics.user().anonymousId()   //segment anonymous id
          }
        }
      });
    });
  };

  const visitPageCall = () => {
    window.analytics.identify(localStorage.getItem('compnay_tg_id') || '', {
      first_name: localStorage.getItem('username') || '',
      last_name: localStorage.getItem('last_name') || '',
      email: localStorage.getItem('email') || '',
      user_id: localStorage.getItem('user_id') || '', //database user_id 
      phone: localStorage.getItem('phone_number') || '',
      company: localStorage.getItem('company_name') || '',
    });

    window.analytics.page({
      referrer: history.state.back ?? '',
    });
  }

  function generateSessionId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  function getNow() {
    return new Date().getTime();
  }

  function setSession() {
    sessionStorage.setItem('sessionId', generateSessionId());
    resetSession();
  }

  function resetSession() {
    let expiryTime = getNow() + 30 * 60 * 1000; // 30 minutes from now
    sessionStorage.setItem('expiryTime', expiryTime);
  }

  function checkSession() {
    let sessionId = sessionStorage.getItem('sessionId');
    let expiryTime = sessionStorage.getItem('expiryTime');

    if (!sessionId || !expiryTime || getNow() > expiryTime) {
      // Session is expired or does not exist, set a new session
      setSession();
    } else {
      // Session is still valid, reset the expiry time
      resetSession();
    }

    return sessionStorage.getItem('sessionId');
}

  function getType(ul, i = 1) {
    let type = ul.split('/');
    let types = ['clearance', 'trending', 'coming-soon', 'favorites'];
    if(types.includes(type[1]) && i == 1) return 'collection';
    return type[i] ? type[i] : type[1]
  }
  function getReferrer(ul) {
    let type = ul
    // return process.env.BASE_URL_API || '';
    return document.URL;
  }

  const FilterArray = (item, by) => {
    let row = item.map(r => r.name);
    return [{ type: 'Filter By ' + by, value: row }];
  };
  const ProductArray = (item, pos, id, type) => {
    let row = [];
    item.forEach((r, k) => {
      row.push({
        list_id: id,
        list_type: type,
        position: k + pos,
        brand: r.brand,
        category: r.product_type,
        product_id: r.id,
        product_sku: r.slug,
        product_name: r.name,
        ...variant(r.variants)
      });
      function variant(v) {
        if (v) {
          let price = [];
          let stock = []
          v.forEach(r => {
            price.push(r.price ?? 0)
            stock.push(r.available_stock)
          });
          stock = stock.reduce((a, b) => a + b);
          return { price: Math.max(...price), quantity: 1 };
        }
        else {
          return { price: 0, quantity: 1 };
        }
      }
    });
    return row;
  }

  // When User Login
  async function TrackUserLogin(id, email, route) {
    let d = {
      page_url: getReferrer(route.path),
      page_path: route.fullPath,
      page_type: getType(route.path),
      page_title: "Medusa Distribution",
      // page_referrer: route.query.page_referrer ?? '',
      page_referrer: history.state.back ?? '',
      page_search: "",
      platform: "website",
      session_id: checkSession()
    };

    let data = {
      email: email,
      user_id: localStorage.getItem('compnay_tg_id'),
      platform: 'website',
      session_id: checkSession(),
      traits: {
        first_name: localStorage.getItem('username') || '',
        last_name: localStorage.getItem('last_name') || '',
        email: localStorage.getItem('email') || '',
        user_id: localStorage.getItem('user_id') || '', //database user_id 
        phone: localStorage.getItem('phone_number') || '',
        company: localStorage.getItem('company_name') || '',
      }
    };
    let integration = {
      integrations: {
        "Google Analytics": {
          clientId: window.analytics.initialized ? window.analytics.user().anonymousId() : ''
        }
      }
    }

    // 1- identify user
    await Analytics.identify(localStorage.getItem('compnay_tg_id') || '', {
      first_name: localStorage.getItem('username') || '',
      last_name: localStorage.getItem('last_name') || '',
      email: localStorage.getItem('email') || '',
      user_id: localStorage.getItem('user_id') || '', //database user_id 
      phone: localStorage.getItem('phone_number') || '',
      company: localStorage.getItem('company_name') || '',
    });

    // 2- page
    setTimeout(() => {
      Analytics.page({
        referrer: history.state.back ?? '',
      });
    }, 1000);

    // 3- page viewed
    setTimeout(() => {
      Analytics.track("page viewed", d, integration);
    }, 1100);

    // 4- user login
    setTimeout(() => {
      Analytics.track("user login", data, integration);
    }, 2000);
  };

  // When user visit on Pages
  function PageVisit(pg) {
    // Analytics.page(pg)
    let data = {
      page_url: getReferrer(pg.path),
      page_path: pg.fullPath,
      page_type: getType(pg.path),
      page_title: "Medusa Distribution",
      page_referrer: history.state.back ?? '',
      page_search: "",
      platform: "website",
      session_id: checkSession()
    };
    visitPageCall();
    SendData("page viewed", data)
    //  Analytics.track("page viewed", data);

    // if(getType(pg.path)=='category' || getType(pg.path)== 'brand'){
    //   ProductListViewed(pg)
    // }

  };

  // Banner Click Event
  function BannerVisit(banner) {
    let data = {
      banner_title: banner.title,
      url: banner.url,
      position: banner.position,
      platform: "website",
      session_id: checkSession()
    };

    SendData("banner click", data)
  };

  // When user visit on Pages
  function PageVisitNonLoggedIn(pg) {
    // Analytics.page(pg)
    let data = {
      page_url: getReferrer(pg.path),
      page_path: pg.fullPath,
      page_type: getType(pg.path),
      page_title: "Medusa Distribution",
      page_referrer: history.state.back ?? '',
      platform: "website",
      session_id: checkSession()
    };
    
    window.analytics.page({
      referrer: history.state.back ?? '',
    });
    SendData("page viewed", data)
  };

  // When User veiwed product list at Brand and Category Screen
  function ProductListViewed(pg, items, list_id = null, pos = 1, list_type = null) {
    if (items) {
      let id = list_id ?? getType(pg.path, 2);
      let type = list_type ?? getType(pg.path);
      let data = {
        value: getValue(items),
        category: getType(pg.path),
        products: ProductArray(items, pos, id, type),
        platform: "website",
        session_id: checkSession()
      };
      SendData("product list viewed", data);
    }
  };

  // When User veiwed product list at Brand and Category Screen
  function ProductListViewedNonLoggedIn(pg, items, list_id = null, pos = 1, list_type = null) {
    if (items) {
      let id = list_id ?? getType(pg.path, 2);
      let type = list_type ?? getType(pg.path);
      let data = {
        // list_id: list_id ?? getType(pg.path, 2),
        // list_type: getType(pg.path),2
        value: 0,
        category: getType(pg.path),
        products: ProductArray(items, pos, id, type),
        platform: "website",
        session_id: checkSession()
      };

      SendData("product list viewed", data);
    }
  };

  // When User Filter product at Brand and Category Screen
  function ProductListFiltered(pg, items, filters, filterBy = 'Category', list_id = null, pos) {
    if (items) {
      let id = list_id ?? getType(pg.path, 2);
      let type = getType(pg.path);
      let data = {
        value: getValue(items),
        category: getType(pg.path),
        filter: FilterArray(filters, filterBy),
        products: ProductArray(items, pos, id, type),
        platform: 'website',
        session_id: checkSession()
      };
      SendData("product list filtered", data);
    }
  };

  function getValue(items) {
    return Array.isArray(items)
      ? items.reduce((a, b) => a + (b.variants && Array.isArray(b.variants))
        ? b.variants.reduce((c, v) => c + (v.price || 0), 0)
        : 0, 0)
      : 0;
  }

  // When User Click on product DropDown
  function ProductClicked(pg, item, p, d = null) {
    let price = d ? d.price : Math.max(...item.variants.map(r => r.price));
    let data = {
      value: price,
      products: [
        {
          list_id: d ? d.id : getType(pg.path, 2),
          list_type: d ? d.type : getType(pg.path),
          position: p,
          brand: item.brand,
          category: item.product_type,
          product_id: item.id,
          product_sku: item.slug,
          product_name: item.name,
          price: price,
          quantity: 1,
          product_inventory_count: d.stock ? d.stock : item.variants.map(r => r.available_stock).reduce((a, b) => a + b),
          platform: "website",
        }
      ],
      session_id: checkSession()
    };
    SendData("product clicked", data);
  };

  // When User Click on product DropDown
  function ProductClickedNonLoggedIn(pg, item, p, d = null) {
    let data = {
      value: 0,
      products: [
        {
          list_id: d ? d.id : getType(pg.path, 2),
          list_type: d ? d.type : getType(pg.path),
          position: p,
          brand: item.brand,
          category: item.product_type,
          product_id: item.id,
          product_sku: item.slug,
          product_name: item.name,
          platform: "website",
        }
      ],
      session_id: checkSession()
    };
    SendData("product clicked", data);
  };

  function ProductViewed(pg, pro) {
    let price = Math.max(...pro.product.variants.map(r => r.price));
    let list = Object.keys(store.getters.getList).length > 0 ? store.getters.getList : null;
    let data = {
      value: price,
      products: [
        {
          list_id: list ? list.list_id : 'prdouct',
          list_type: list ? list.list_type : 'product',
          position: list ? list.position : store.getters.getProPosition,
          brand: pro.product.brand,
          category: pro.product.product_type,
          product_id: pro.product.id,
          product_sku: pro.product.slug,
          product_name: pro.product.name,
          price: price,
          quantity: 1,
          product_inventory_count: pro.product.variants.map(r => r.available_stock).reduce((a, b) => a + b),
          stock_status: pro.product.variants.find(r => r.available_stock) ? true : false,
          ranking_in_brand: pro.rank_brand,
          ranking_in_category: pro.rank,
          platform: 'website'
        }
      ],
      session_id: checkSession()
    };
    // data.value = data.price * data.quantity;
    SendData("product viewed", data);
  };

  function ProductViewedNonLoggedIn(pg, pro) {
    let list = Object.keys(store.getters.getList).length > 0 ? store.getters.getList : null;
    let data = {
      value: 0,
      products: [
        {
          list_id: list ? list.list_id : 'product',
          list_type: list ? list.list_type : 'product',
          position: list ? list.position : store.getters.getProPosition,
          brand: pro.product.brand,
          category: pro.product.product_type,
          product_id: pro.product.id,
          product_sku: pro.product.slug,
          product_name: pro.product.name,
          platform: 'website'
        }
      ],
      session_id: checkSession()
    };
    // data.value = data.price * data.quantity;
    SendData("product viewed", data);
  };

  function addOrRemove(segmentQty, item, cRank, bRank) {
    let data = {
      value: item.singlePrice * segmentQty,
      cart_id: item.cart_id,
      products: [
        {
          list_id: item.list_id,
          list_type: item.list_type,
          position: item.position,
          brand: item.brandName,
          category: item.category,
          product_id: item.productId,
          product_sku: item.productName,
          product_name: item.productName,
          variant_id: item.variantId,
          variant: item.optionLabel,
          variant_size: item.singlePrice * item.availableStock,
          variant_description: '',
          price: item.singlePrice,
          quantity: segmentQty,
          product_inventory_count: item.availableStock,
          platform: 'website'
        }
      ],
      session_id: checkSession()
    };
    return data;
  };

  function ProductAdded(segmentQty, items, cR = '', bR = '') {
    const data = addOrRemove(segmentQty, items, cR, bR);

    SendData("product added", data);
    //removes item from local storage when request fullfiled
    localStorage.removeItem('segmentQty');
  };

  function ProductRemoved(segmentQty, items, cR = '', bR = '') {
    const data = addOrRemove(segmentQty, items, cR, bR);
    SendData("product removed", data);
    //removes item from local storage when request fullfiled
    localStorage.removeItem('segmentQty');
  };

  // For Lightning Order
  function ProductsAdded(items) {
    const products = [];

    items.forEach((item, i) => {
      products.push({
        list_id: 'lightning-order',
        list_type: 'lightning order',
        position: i+1,
        brand: item.brand,
        category: item.product_type,
        product_id: item.product_id,
        product_sku: item.slug,
        product_name: item.productName,
        variant_id: item.id,
        variant: item.opt1,
        variant_description: '',
        price: item.price,
        quantity: item.qty,
        platform: 'website'
      });
    });

    let data = {
      value: items.reduce((a, b) => a + (b.price * b.qty), 0).toFixed(2),
      products: products,
      session_id: checkSession()
    };
  
    SendData("product added", data);
  };

  function ClearCart(items) {
    let data = {
      value: items.reduce((a, b) => a + (b.price * b.quantity), 0).toFixed(2),
      products: items,
      session_id: checkSession()
    }
    SendData("product removed", data)
  };

  function ProductNotify(from, pg, item, pro, type = null) {
    let data = {
      value: item.price ? item.price : 0,
      products: [
        {
          list_id: type ? type : pro.brand,
          list_type: getType(pg.path),
          position: store.getters.getProPosition,
          brand: pro.brand,
          category: pro.product_type,
          product_id: pro.id,
          product_sku: pro.slug,
          product_name: pro.name,
          variant_id: item.id,
          variant: item.name ?? item.productName,
          variant_size: item.available_stock ?? 0,
          price: item.price,
          quantity: 1,
          platform: 'website'
        }
      ],
      session_id: checkSession()
    };
    if (from == "add") SendData("product notification added", data)
    else if (from == "remove") SendData("product notification removed", data)
  };

  function ProductSearched(qry, pos, item, len = [], ps = []) {
    let unique_brands = len.map(r => r.brand).filter((v, i, a) => a.indexOf(v) === i);
    let data = {
      query: qry,
      click_type: 'Products',
      click_value: item.name ?? "All Products",
      click_position: pos,
      popular_search_results: ps.length,
      brand_results: unique_brands?.length,
      platform: 'website',
      products_results: len?.length,
      session_id: checkSession()
    };
    SendData("products searched", data)
  };

  function ProductSearchedNonLoggedIn(qry, pos, item, len = [], ps = []) {
    let unique_brands = len.map(r => r.brand).filter((v, i, a) => a.indexOf(v) === i);
    let data = {
      query: qry,
      click_type: 'Products',
      click_value: item.name ?? "All Products",
      click_position: pos,
      popular_search_results: ps.length,
      brand_results: unique_brands?.length,
      platform: 'website',
      products_results: len?.length,
      session_id: checkSession()
    };
    SendData("products searched", data)
  };

  function CartArray(item) {
    // update  track checkout and  order complete
    let arr = [];
    item.forEach(el => {
      arr.push({
        list_id: el.list_id,
        list_type: el.list_type,
        position: el.position,
        // brand:el.brandName,
        // category:el.categoryName,
        product_id: el.productId,
        // filter_by: el.sort,
        product_sku: el.productName.toLowerCase().replace(" ", "-"),
        product_name: el.productName,
        variant_id: el.variantId,
        variant_size: el.qty * el.singlePrice,
        //     variant_decription:'',
        price: el.singlePrice,
        quantity: el.qty,
        //     rank_in_brand:'',
        //     rank_in_category:'',

      });
    });
    return arr
  };

  function TrackCheckout(rec, items) {
    let data = {
      affiliation: "Medusa",
      value: rec.gTotal,
      revenue: rec.subTotal,
      shipping: rec.shipping,
      tax: rec.taxTotal,
      reward_name: "",
      points_redeemed: "",
      discount: "",
      coupon: rec.coupons[0] ? rec.coupons[0].code : '',
      currency: "USD",
      products: CartArray(items),
      platform: "website",
      cart_id: rec.cart_id,
      session_id: checkSession()
    };
    SendData("checkout started", data)
  };
  function TrackCheckoutCompleted(rec) {
    let data = {
      step: "2",
      value: rec.gTotal,
      revenue: rec.subTotal,
      shipping: rec.shipping,
      tax: rec.taxTotal,
      reward_name: rec.reward.name ?? '',
      points_redeemed: rec.reward.points_redeemed ?? '',
      discount: rec.reward.discount ?? '',
      coupon: rec.coupons[0] ? rec.coupons[0].code : '',
      currency: "USD",
      payment_method: rec.payment_method,
      shipping_method: rec.shipping_method,
      platform: "website",
      session_id: checkSession()
    };
    SendData("checkout step completed", data)
  };

  function getTotal(data) {
    let temp = 0;
    if (data)
      data.forEach(element => temp += element.qty * element.singlePrice);
    return temp;
  };

  function OrderCompleted(d) {
    let data = {
      order_id: d.id,
      affiliation: 'Medusa',
      total: d.total,
      sub_total: getTotal(d.order_line_items),
      revenue: getTotal(d.order_line_items),
      shipping: d.ShippingTotal,
      tax: d.taxes,
      discount: Number(d.discounts.reduce((a, b) => a.price + b.price, 0).toFixed(2)),
      reward_name: '',
      points_redeemed: '',
      points_earned: '',
      coupon: '',
      curency: 'USD',
      products: CartArray(d.order_line_items),
      platform: 'website',
      cart_id: d.cart.id,
      session_id: checkSession()
    };
    SendData("order completed", data)
  };

  function CoinsEarn(v) {
    let data = {
      earning_method: 'checkout',
      coins_earned: v,
      platform: 'website',
      session_id: checkSession()
    };
    SendData("coins earned", data)
  };

  function CoinsRedeemed(v) {
    let data = {
      redeemption_method: 'checkout',
      coins_redeemed: v,
      platform: 'website',
      session_id: checkSession()
    };
    SendData("coins redeemed", data)
  };

  return {
    TrackUserLogin,
    PageVisit,
    PageVisitNonLoggedIn,
    ProductListViewed,
    ProductListViewedNonLoggedIn,
    ProductListFiltered,
    ProductClicked,
    ProductClickedNonLoggedIn,
    ProductViewed,
    ProductViewedNonLoggedIn,
    ProductAdded,
    ProductRemoved,
    ClearCart,
    ProductNotify,
    ProductSearched,
    ProductSearchedNonLoggedIn,
    TrackCheckout,
    TrackCheckoutCompleted,
    OrderCompleted,
    CoinsEarn,
    CoinsRedeemed,
    BannerVisit,
    ProductsAdded
  }
}