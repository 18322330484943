export const product = {
    state: () => ({
        position: 1,
        variantId: 0,
        fromCheckout: false,
        isRank: false,
        list: {}, 
    }),

    getters: {
        getProPosition: (state) => state.position,
        getVarID: (state) => state.variantId,
        getFromCheckOut: (state) => state.fromCheckout,
        getIsRank: (state) => state.isRank,
        getList: (state) => state.list,
    },

    actions: {
        setProPosition({ commit }, data) {
            commit('SET_PRODUCT_POSITION', data);
        },
        setVarID({ commit }, data) {
            commit('SET_VARIANT_ID', data);
        },
        setFromCheckOut({ commit }, data) {
            commit('SET_FROM_CHECKOUT', data);
        },
        setIsRank({ commit }, data) {
            commit('SET_IS_RANK', data);
        },
        setList({ commit }, data) {
            commit('SET_LIST', data);
        }
    },
    
    mutations: {
        SET_PRODUCT_POSITION(state, data) {
            state.position = data
        },
        SET_VARIANT_ID(state, data) {
            state.variantId = data
        },
        SET_FROM_CHECKOUT(state, data) {
            state.fromCheckout = data
        },
        SET_IS_RANK(state, data) {
            state.isRank = data
        },
        SET_LIST(state, data) {
            state.list = data
        }
    },
    
}